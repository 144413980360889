<template>
  <div class="mt-3" v-if="!isLoggedIn">
    <h1 class="mb-3">
      {{ $t('pwcf.t'+(isUser?'u':'c')) }}
    </h1>
    <b-card class="mt-3" header-tag="nav">
      <template #header>
        <b-nav card-header tabs>
          <b-nav-item to="/" exact exact-active-class="active"><b-icon-house v-if="!isXs" v-bind="iH" /> {{ $t('nav.home') }}</b-nav-item>
          <b-nav-item active exact exact-active-class="active"><Icon type="e" isNav :isButton="false" /> {{ $t('btn.c') }}</b-nav-item>
        </b-nav>
      </template>
      <p class="text-justify">{{ $t('pwcf.ex') }}</p>
      <p class="text-justify" v-if="!isUser">{{ $t('pwcf.exc') }}</p>
      <b-form class="text-center mt-3" id="pwcForm" @submit.stop.prevent>
        <b-row>
          <b-form-group :label="$t('lgin.f.emaill')" label-for="pwcEmail" class="text-left col-12">
            <b-form-input type="email" id="pwcEmail" v-model="emailAddress" :state="valEmail" required />
            <b-form-invalid-feedback :state="valEmail">
              {{ $t('lgin.f.emailf') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-row>
        <b-row v-if="isUser">
          <b-form-group :label="$t('addr.lfnl')" label-for="pwcFn" class="text-left col-6">
            <b-form-input type="text" id="pwcFn" v-model="userf.firstName" :state="valTxt(userf.firstName, 2, 32)" class="mt-2" required />
            <b-form-invalid-feedback :state="valTxt(userf.firstName, 2, 32)">
              {{ $t('addr.ff') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('addr.lsnl')" label-for="pwcLn" class="text-left col-6">
            <b-form-input type="text" id="pwcLn" v-model="userf.lastName" :state="valTxt(userf.lastName, 2, 32)" class="mt-2" required />
            <b-form-invalid-feedback :state="valTxt(userf.lastName, 2, 32)">
              {{ $t('addr.ff') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-row>
        <b-row v-if="!isUser">
          <b-form-group :label="$t('addr.locnl')" label-for="pwcName" class="text-left col-12">
            <b-form-input type="text" id="pwcName" v-model="customerf.invoiceAddress.name" :state="valTxt(customerf.invoiceAddress.name, 2, 75)" required />
            <b-form-invalid-feedback :state="valTxt(customerf.invoiceAddress.name, 2, 75)">
              {{ $t('addr.ff') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-row>
        <b-row v-if="!isUser">
          <b-form-group :label="$t('addr.ltxl')" label-for="pwcTx" class="text-left col-6">
            <b-form-input type="text" id="pwcTx" v-model="customerf.invoiceAddress.taxNumber" :state="valTxt(customerf.invoiceAddress.taxNumber, 2, 50)" class="mt-2" required />
            <b-form-invalid-feedback :state="valTxt(customerf.invoiceAddress.taxNumber, 2, 50)">
              {{ $t('addr.ff') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('addr.lrnl')" label-for="pwcRn" class="text-left col-6">
            <b-form-input type="text" id="pwcRn" v-model="customerf.invoiceAddress.registerNumber" :state="valRn" class="mt-2" required />
            <b-form-invalid-feedback :state="valRn">
              {{ $t('addr.ff') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-row>
        <AddressContent idPrefix="icnf" @status="getAddrStatus" form="pwcForm" v-if="isUser"
            :aCity="userf.city" @aCity="userf.city = $event"
            :aStreet="userf.street" @aStreet="userf.street = $event"
            :aNumber="userf.number" @aNumber="userf.number = $event"
            :aZip="userf.zip" @aZip="userf.zip = $event"
            :aCountryId="userf.countryId" @aCountryId="userf.countryId = $event"
            :aStateId="userf.stateId" @aStateId="userf.stateId = $event" />
        <AddressContent idPrefix="icnf" @status="getAddrStatus" form="pwcForm" v-if="!isUser"
            :aCity="customerf.invoiceAddress.city" @aCity="customerf.invoiceAddress.city = $event"
            :aStreet="customerf.invoiceAddress.street" @aStreet="customerf.invoiceAddress.street = $event"
            :aNumber="customerf.invoiceAddress.number" @aNumber="customerf.invoiceAddress.number = $event"
            :aZip="customerf.invoiceAddress.zip" @aZip="customerf.invoiceAddress.zip = $event"
            :aCountryId="customerf.invoiceAddress.countryId" @aCountryId="customerf.invoiceAddress.countryId = $event"
            :aStateId="customerf.invoiceAddress.stateId" @aStateId="customerf.invoiceAddress.stateId = $event" />
        <div v-if="!isUser">
          <h3 class="mb-3 text-left">
            {{ $t('pwcf.custh') }}
          </h3>
          <b-row>
            <b-form-group label-for="icb" class="text-left col-12">
              <b-form-checkbox id="icb" v-model="invIsLoc" name="icb" :value="true" :uncheckedValue="false" class="text-left">
                {{ $t('pwcf.btn') }}
              </b-form-checkbox>
            </b-form-group>
          </b-row>
          <div v-if="!invIsLoc">
            <b-row>
              <b-form-group :label="$t('addr.locnl')" label-for="pwcIName" class="text-left col-12">
                <b-form-input type="text" id="pwcIName" v-model="customerf.locationAddress.name" :state="valTxt(customerf.locationAddress.name, 2, 75)" required />
                <b-form-invalid-feedback :state="valTxt(customerf.locationAddress.name, 2, 75)">
                  {{ $t('addr.ff') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-row>
            <AddressContent idPrefix="lcnf" @status="getLocAddrStatus" form="pwcForm"
                :aCity="customerf.locationAddress.city" @aCity="customerf.locationAddress.city = $event"
                :aStreet="customerf.locationAddress.street" @aStreet="customerf.locationAddress.street = $event"
                :aNumber="customerf.locationAddress.number" @aNumber="customerf.locationAddress.number = $event"
                :aZip="customerf.locationAddress.zip" @aZip="customerf.locationAddress.zip = $event"
                :aCountryId="customerf.locationAddress.countryId" @aCountryId="customerf.locationAddress.countryId = $event"
                :aStateId="customerf.locationAddress.stateId" @aStateId="customerf.locationAddress.stateId = $event" />
          </div>
        </div>

        <b-form-group class="text-right mt-3 mb-0">
          <b-button type="submit" variant="primary" class="ml-2" id="pwcSubmit" :disabled="!val" @click="sendData()">
            <Icon type="e" /> {{ $t('btn.c') }}
          </b-button>
        </b-form-group>
      </b-form>
    </b-card>

    <ModalYesNoGeneric id="pwcCust" :title="$t('acc.tdel')" hideSecondary
        :btnTextSubmit="$t('btn.x')" btnIconSubmit="x" btnVariantSubmit="secondary"
        @submit="$router.push({ name: 'AnuHomecust' })">
      <p>
        {{ $t('pwcf.cust') }}
      </p>
    </ModalYesNoGeneric>
  </div>
</template>

<script>
import { BIconHouse } from 'bootstrap-vue';
import Icon from '@/components/Icon';
import { REST } from '@/components/RestCall';
import AddressContent from '@/components/shared/AddressContent';
import ModalYesNoGeneric from '@/components/ModalYesNoGeneric';

export default {
  data() {
    return {
      uuid4: null,
      emailAddress: null,
      userf: {
        firstName: null,
        lastName: null,
        taxNumber: null,
        street: null,
        number: '',
        city: null,
        zip: null,
        countryId: 1,
        stateId: null,
      },
      customerf: {
        locationAddress: {
          name: null,
          street: null,
          number: '',
          city: null,
          zip: null,
          countryId: 1,
          stateId: null,
        },
        invoiceAddress: {
          name: null,
          taxNumber: null,
          registerNumber: null,
          street: null,
          number: '',
          city: null,
          zip: null,
          countryId: 1,
          stateId: null,
        },
      },
      isUser: null,
      isAddrSuccess: false,
      isLocAddrSuccess: false,
      invIsLoc: true,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    valEmail() {
      if (this.emailAddress === null) return null;
      let rExp = /(.+)@(.+){2,}\.([a-z]+){2,}/;
      return rExp.test(this.emailAddress.toLowerCase());
    },
    valRn() {
      if (this.customerf.invoiceAddress.registerNumber === null) return null;
      return this.customerf.invoiceAddress.registerNumber === '' || this.valTxt(this.customerf.invoiceAddress.registerNumber, 2, 100);
    },
    val() {
      if (this.valEmail == true && this.isAddrSuccess == true) {
        if (this.isUser) {
          return this.valTxt(this.userf.firstName, 2, 32) && this.valTxt(this.userf.lastName, 2, 32);
        } else {
          if (this.valTxt(this.customerf.invoiceAddress.name, 2, 75) && this.valRn !== false && this.valTxt(this.customerf.invoiceAddress.taxNumber, 2, 50)) {
            return this.invIsLoc || (this.valTxt(this.customerf.locationAddress.name, 2, 75) && this.isLocAddrSuccess);
          }
        }
      }
      return false;
    },
    iH() {
      return this.$store.getters.iH;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  created() { // for initial start, may lead to prepare called twice
    this.prepare(this.$route.params.uuid, this.$route.meta.isUser);
  },
  beforeRouteLeave(to, _, next) { // when switching from confirmcust to confirmuser
    if (to.name == 'AnuConfirm') {
      this.prepare(to.params.uuid, to.meta.isUser);
    }
    next();
  },
  beforeRouteUpdate(to, _, next) { // when changing the uuid parameter
    if (to.name == 'AnuConfirm') {
      this.prepare(to.params.uuid, to.meta.isUser);
    }
    next();
  },
  methods: {
    valTxt(txt, minLen, maxLen) {
      if (txt == null) return null;
      if (typeof txt != 'string') return false;
      return (txt.length >= minLen && txt.length <= maxLen) ? true : false;
    },
    getAddrStatus(state) {
      this.isAddrSuccess = state;
    },
    getLocAddrStatus(state) {
      this.isLocAddrSuccess = state;
    },
    prepare(uuid, isUser) {
      if (this.isLoggedIn) {
        this.$store.commit('showWarn', this.$t('pwcf.w'));
        this.$router.replace('/').catch(() => {});
      } else {
        this.uuid4 = uuid;
        this.isUser = isUser;
      }
    },
    sendData() {
      if (this.val) {
        let data = null;
        if (this.isUser) {
          data = {...this.userf, emailAddress: this.emailAddress, uuid4: this.uuid4};
        } else {
          data = {...this.customerf, emailAddress: this.emailAddress, uuid4: this.uuid4};
          if (data.invoiceAddress.registerNumber === '') data.invoiceAddress.registerNumber = null;
          if (this.invIsLoc) {
            data.locationAddress = {...data.locationAddress, ...data.invoiceAddress};
            delete data.locationAddress.taxNumber;
            delete data.locationAddress.registerNumber;
          }
        }
        REST.patch('/tasks/confirm' + (this.isUser ? 'user' : 'cust') + '/' + this.uuid4, data
          ).then(resp => {
            if (this.isUser) {
              this.$router.push('/');
              this.$bvModal.show('loginWindow');
            } else {
              this.$bvModal.show('pwcCust');
            }
            this.$store.commit('showSuccess', resp.d.message);
          }).catch(e => {
            this.$store.commit('showWarn', e.message);
          });
      }
    }
  },
  components: {
    Icon,
    BIconHouse,
    AddressContent,
    ModalYesNoGeneric,
  },
}
</script>
